import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import BasicPage from "../templates/BasicPage"
import BulkText from "../components/BulkText"

const htmlDump = (
  <>
    <h2>Genie Delivery Terms of Service</h2>
    <p>
      <strong>
        <br />
      </strong>
      <strong>Last updated:&nbsp;</strong>19 September 2020
    </p>
    <p>
      This page (together with the documents referred to in it) tells you the
      terms (the &ldquo;<strong>Terms</strong>&rdquo;) which apply when you
      order any items (the&nbsp;<strong>"Items"</strong>) from our Genie
      Delivery website or mobile applications and related services (each
      referred to as an&nbsp;<strong>&ldquo;Application&rdquo;</strong>).
    </p>
    <p>
      Please read these Terms carefully before creating a Genie Delivery
      account, using our Application, or placing an order. If you have any
      questions relating to these Terms please
      contact&nbsp;team@geniedelivery.co.uk.
    </p>
    <p>
      If you are a consumer, you have certain legal rights when you order Items
      using our Application. You can find more information about these rights
      at:&nbsp;
      <a href="https://www.citizensadvice.org.uk/consumer/">
        https://www.citizensadvice.org.uk/consumer/
      </a>
      . Your legal rights are not affected by these Terms, which apply in
      addition to them and do not replace them. By creating a Genie Delivery
      account, using our Application, or placing an order, you confirm that you
      accept these Terms.
    </p>
    <h3>1. Information About Us</h3>
    <p>
      Geniedelivery.co.uk is operated by Genie Delivery Limited, a company
      incorporated and registered in England and Wales, whose registered office
      is at Ketton Suite, The King Centre, Main Road, Barleythorpe, Oakham,
      Rutland, LE15 7WD, United Kingdom. Our Company registration number is
      12863801. Our VAT number is 357 8173 64. You may contact us at
      team@geniedelivery.co.uk.
    </p>
    <h3>2. Purpose</h3>
    <p>
      Our Company allows you to order Items from us or our partners (&ldquo;
      <strong>Partner Stores&rdquo;</strong>) for delivery (our &ldquo;
      <strong>Service</strong>&rdquo;). Once you have placed an order, delivery
      of your Items will be arranged by Genie Delivery.
    </p>
    <h3>3. Your Account</h3>
    <p>
      Before you can place orders for Items using our Application, you need to
      sign up with Genie Delivery. When you open an account, you may create a
      password, or other secure login method, and may also have to provide
      credit card details. You must keep any password you create, or other
      secure login method, secret, and prevent others from accessing your email
      account or mobile phone. If another person uses these methods to access
      your account, you will be responsible to pay for any Items they order, and
      we are not responsible for any other losses you suffer, unless the person
      using your password obtained it because we did not keep it secure.
    </p>
    <p>
      You may close your account at any time by contacting
      team@geniedelivery.co.uk. We may suspend your access to your account, or
      close it permanently, if we believe that your account has been used by
      someone else. We may also close your account if in our opinion you are
      abusing our Service (for example, by applying for refunds or credit to
      which we do not consider you are entitled, making repeated unreasonable
      complaints, mistreating our staff or riders, or any other good reason).
    </p>
    <h3>4. Service Availability</h3>
    <p>
      Genie Delivery has a prescribed service area. This service area may change
      at any time due to factors such as weather or demand on our service. This
      is to ensure that Items reach your door at their best. If you try to order
      a delivery to a location outside the delivery area or operating hours, or
      the Application is otherwise unavailable for any reason, we will notify
      you that ordering will not be possible.
    </p>
    <h3>5. Orders</h3>
    <p>
      When you place an order through our Application, it needs to be accepted
      by us before it is confirmed. We will send you a notification if your
      order has been accepted (the&nbsp;<strong>"Confirmation Notice"</strong>).
      The contract for the supply of any Item you have ordered comes into
      existence when we send the Confirmation Notice. You are responsible for
      paying for all Items ordered using your account, and for related delivery
      charges, and for complying with these Terms, even if you have ordered the
      Item for someone else. A minimum order value policy may apply and will be
      displayed on our Application. All Items are subject to availability.
    </p>
    <h3>6. Delivery</h3>
    <p>
      When you place an order, we will tell you an estimated delivery time for
      your Item,&nbsp;
      <strong>but we will attempt delivery as soon as possible</strong>; you
      must therefore be available to accept delivery from the time you place the
      order.
    </p>
    <p>
      Unfortunately, despite our best efforts, things do not always go to plan
      and factors such as traffic and weather conditions may prevent us from
      delivering your Item on time. If your order is more than 15 minutes late,
      and we haven&rsquo;t notified you giving you the option to cancel your
      order, we will work with you to make things right unless you have caused
      the delay (e.g. because you gave us the wrong address or did not come to
      the door).
    </p>
    <p>
      We will attempt delivery at the address you provide to us when you place
      your order. If you need to change the delivery location after you have
      placed your order, we may be able to change to the address to an
      alternative one if you let us know before the rider has been dispatched,
      and the new address is within the same zone as the address you originally
      ordered your Item to. If we cannot change the delivery address, you have
      the option to cancel the order, but if the rider has been dispatched you
      will also be charged for delivery.
    </p>
    <p>
      You will still be charged for the Item and for delivery in the event of a
      failed delivery if you have caused such failure for any reason. Reasons
      you might cause a delivery to fail include (but are not limited to):
    </p>
    <ul>
      <li>
        <p>
          You do not come to the door, did not pick up the phone when the rider
          contacted you using the contact information you have provided us
          and/or you picked up the phone but then failed to provide access
          within a reasonable amount of time, and the rider is unable to find a
          safe location to leave the items.
        </p>
      </li>
      <li>
        <p>
          The rider refuses to deliver the Item to you in accordance with
          section 8 (Age Restricted Products).
        </p>
      </li>
    </ul>
    <p>
      In order to ensure the safety of our customers and riders, all deliveries
      will be made as &lsquo;no-contact&rsquo; deliveries. Please see our FAQ
      section for updated COVID delivery related policies.
    </p>
    <h3>7. Your Rights if Something is Wrong With Your Items</h3>
    <p>
      You have a legal right to receive goods which comply with their
      description, which are of satisfactory quality and which comply with any
      specific requirements you tell us about (and we agree to) before you place
      your order. If you believe that the Items you have been delivered do not
      comply with these legal rights, please let us know. We may request a
      photograph showing the problem if it is something that can be seen by
      inspecting the Items. We will provide a refund or account credit in
      respect of the affected part of the Item, and also in respect of delivery
      if the whole order was affected, unless we have reasonable cause to
      believe that the problem was caused after delivery.
    </p>
    <p>
      Prior to processing your refund or account credit, we may take into
      account relevant factors including the details of the order, including
      your account history, what happened on delivery and information from the
      rider.
    </p>
    <h3>8. Age Restricted Products</h3>
    <p>
      Age restricted products (including, without limitation, alcohol, tobacco
      and cigarettes) can only be sold and delivered to persons aged 18 or over.
      By placing an order for an age restricted product, you confirm that you
      are at least 18 years old. Genie Delivery operates an age verification
      policy whereby customers ordering age restricted products will be asked by
      the rider to provide proof that they are aged 18 or over before the
      delivery is completed. The rider may refuse to deliver any age restricted
      product to any person unless they can provide valid photo ID proving that
      they are aged 18 or over. The rider may refuse to deliver alcohol to any
      person who is, or appears to be under the influence of either alcohol or
      drugs. If delivery of any age restricted product is refused, you will
      still be charged for the relevant Item and for delivery.
    </p>
    <h3>9. Cancellation</h3>
    <p>
      You may cancel an order without charge at any time before the rider has
      been dispatched (a&nbsp;<strong>"Started Order"</strong>). If you wish to
      cancel an order before it becomes a Started Order, please contact us
      immediately, via our Application. If we confirm the order was not a
      Started Order, we will refund your payment (excluding any applied
      discount). If you cancel any order after it becomes a Started Order, you
      will be charged the full price for the Items, and if the rider has been
      dispatched you will also be charged for delivery.
    </p>
    <p>
      Genie Delivery may notify you that an order has been cancelled at any
      time. You will not be charged for any orders cancelled by us, and we will
      reimburse you for any payment already made using the same method you used
      to pay for your order. We may also apply credit to your account to reflect
      any inconvenience caused.
    </p>
    <h3>10. Prices, Payment and Offers</h3>
    <p>
      Prices include VAT. You confirm that you are using our Service for
      personal, non-commercial use unless you request a VAT invoice. Genie
      Delivery may operate dynamic pricing some of the time, which means that
      prices of Items and delivery may change while you are browsing or while
      your item is in your cart prior to purchase. We reserve the right to
      charge a Service Fee,&nbsp;which may be subject to change,&nbsp;for the
      provision of our Services.&nbsp;You will be notified of any applicable
      Service Fee and taxes prior to purchase on the checkout page on our
      Application. No changes will affect existing confirmed orders unless there
      is an obvious pricing mistake. If there is an obvious pricing mistake we
      will notify you as soon as we can and you will have the choice of
      confirming the order at the original price or cancelling the order without
      charge and with a full refund of any money already paid. Where Genie
      Delivery make a delivery, we may also charge you a delivery fee. This will
      be notified to you during the order process before you complete your
      order.
    </p>
    <p>
      The total price of your order will be set out on the checkout page on our
      Application, including the prices of Items and Delivery and applicable
      Service Fees and taxes.
    </p>
    <p>
      Payment for all Items and deliveries can be made on our Application by
      credit or debit card, or other payment method made available by Genie
      Delivery. Once your order has been confirmed your credit or debit card
      will be authorised and the total amount marked for payment. Payment may
      also be made by using vouchers or account credit.
    </p>
    <p>
      We are authorised by our Partner Stores to accept payment on their behalf
      and payment of the price of any Items or delivery charges to us will
      fulfil your obligation to pay the price to the Partner Stores. Where cash
      payment is possible, this will be made clear on our Application before you
      place your order.
    </p>
    <h3>11. Our Responsibility for Loss or Damage That You Suffer</h3>
    <p>
      We are responsible to you for any loss or damage that you suffer that is a
      foreseeable result of our breaking these Terms or of failing to use
      reasonable care and skill in relation to your use of our Service. We are
      not responsible for any loss or damage that is not foreseeable. Loss or
      damage is &ldquo;foreseeable&rdquo; if it is either obvious that it will
      happen, or if you told us that it might happen, for example if you tell us
      about particular circumstances that might increase the loss or damage
      arising from our breach of these Terms before you place an order.
    </p>
    <p>
      We do not exclude or limit our responsibility to you for loss or damage
      where it would be unlawful to do so. This includes any responsibility for
      death or personal injury caused by our failure, or our employees&rsquo;,
      agents&rsquo; or subcontractors&rsquo; failure, to use reasonable care and
      skill; for fraud or fraudulent misrepresentation; for breach of your legal
      rights in relation to the Items, as summarised at part 7 above; or for
      defective Items under the Consumer Protection Act 1987. Subject to the
      previous paragraph, we are not responsible for any loss or damage that you
      suffer as a result of your own breach of these Terms, or as a result of
      any IT hardware or software failure other than a failure in our
      Applications.
    </p>
    <h3>12. Data Protection</h3>
    <p>
      We process your personal data in accordance with our Privacy Policy which
      can be found on our website.
    </p>
    <h3>13. Other Terms</h3>
    <p>
      If either we or you have any right to enforce these Terms against the
      other, that right will not be lost even if the person who has the right
      delays enforcing it, or waives their right to enforce it in any instance.
      If a court or other authority decides that any part of these Terms is
      illegal or ineffective, the rest of the terms will be unaffected and will
      remain in force.
    </p>
    <p>
      We may change these Terms from time to time. If we make any changes which
      affect your rights in relation to our Service, we will notify you. Changes
      to the Terms will not affect any orders you have placed where we have sent
      the Confirmation Notice. These Terms are governed by English law and you
      can bring legal proceedings in relation to our Service in the English
      courts. If you live in Scotland you can bring legal proceedings in respect
      of the Items in either the Scottish or the English courts. If you live in
      Northern Ireland you can bring legal proceedings in respect of the Items
      in either the Northern Irish or the English courts. We are required by EU
      law to provide&nbsp;
      <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=EN">
        this link
      </a>
      &nbsp;to the EU&rsquo;s online dispute resolution portal, however we do
      not participate in dispute resolution under this process.
    </p>
  </>
)

const TermsOfServicePage = (): JSX.Element => (
  <Layout>
    <SEO
      title="Terms of Service"
      description="Here you'll find the fine print about Genie and our service."
    />
    <BasicPage>
      <BulkText content={htmlDump} />
    </BasicPage>
  </Layout>
)

export default TermsOfServicePage
